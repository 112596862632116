<template>
    <div>
        <div class="bg-cce6ff p-5" style="margin-left: -6px; margin-right: -6px">
            <div class="container-fluid">
                <h1 class="m-0">
                    IMPOSTAZIONI GENERALI
                </h1>
                <p class="m-0" v-if="
                    !loaders.reading.settings
                ">
                    Visualizza le impostazioni generali della piattaforma
                </p>
                <br />
                Visualizza impostazioni per anno:
                <select id="dob" class="form-control text-uppercase col-3" @input="change_year($event.target.value)"> 
                    <option v-for="year in years" :key="year" v-bind:value="year">{{ year }}</option>
                </select>               
            </div>
        </div>
        <loader v-if="loaders.reading.settings" />
        <div class="container py-5" v-if="
            !loaders.reading.settings
        ">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card-space mb-3">
                        <div class="card card-bg no-after">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-3">

                                        <h5 class="card-title m-0">
                                            SMS <br />SCREENING MAMMOGRAFICO
                                        </h5>
                                        <div class="card-text pb-3">
                                        Il numero massimo di sms che possono essere inviati
                                        </div>

                                        <strong>{{form.max_sms_mammografico}}</strong>
                                    </div>
                                    <div class="col-lg-1"></div>
                                    <div class="col-lg-8">
                                        <h5 class="card-title m-0">
                                            CONTATORE SMS INVIATI ANNO {{ form.year }}
                                        </h5>
                                        <div class="row mt-4">
                                            <div class="col-lg-3">
                                                SMS inviati<br />
                                                {{form.counter.mammografico.sms_sent}}
                                            </div>
                                            <div class="col-lg-3">
                                                SMS conferma<br />
                                                {{form.counter.mammografico.sms_confirm}}
                                            </div>
                                            <div class="col-lg-3">
                                                SMS sollecito<br />
                                                {{form.counter.mammografico.sms_reminder}}
                                            </div>  
                                            <div class="col-lg-3 text-center font-weight-bold">
                                                TOTALE <br />
                                                {{form.counter.mammografico.total_sms}}
                                            </div>                                                                                                                                    
                                        </div>                                                                               
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>    
                    <div class="card-space mb-3">    
                        <div class="card card-bg no-after">
                            <div class="card-body">                                    
                                <div class="row">
                                    <div class="col-lg-3">

                                        <h5 class="card-title m-0">
                                            SMS <br />SCREENING CITOLOGICO
                                        </h5>
                                        <div class="card-text pb-3">
                                        Il numero massimo di sms che possono essere inviati
                                        </div>

                                        <strong>{{form.max_sms_citologico}}</strong>
                                    </div>
                                    <div class="col-lg-1"></div>
                                    <div class="col-lg-8">
                                        <h5 class="card-title m-0">
                                            CONTATORE SMS INVIATI ANNO {{ form.year }}
                                        </h5>
                                        <div class="row mt-4">
                                            <div class="col-lg-3">
                                                SMS inviati<br />
                                                {{form.counter.citologico.sms_sent}}
                                            </div>
                                            <div class="col-lg-3">
                                                SMS conferma<br />
                                                {{form.counter.citologico.sms_confirm}}
                                            </div>
                                            <div class="col-lg-3">
                                                SMS sollecito<br />
                                                {{form.counter.citologico.sms_reminder}}
                                            </div>  
                                            <div class="col-lg-3 text-center font-weight-bold">
                                                TOTALE <br />
                                                {{form.counter.citologico.total_sms}}
                                            </div>                                                                                                                                    
                                        </div>                                                                               
                                    </div>
                                </div> 
                            </div> 
                        </div> 
                    </div>             
                    <div class="card-space mb-3">    
                        <div class="card card-bg no-after">
                            <div class="card-body">                                         
                                <div class="row">
                                    <div class="col-lg-3">

                                        <h5 class="card-title m-0">
                                            SMS <br />SCREENING COLON RETTO
                                        </h5>
                                        <div class="card-text pb-3">
                                        Il numero massimo di sms che possono essere inviati
                                        </div>

                                        <strong>{{form.max_sms_colonretto}}</strong>
                                    </div>
                                    <div class="col-lg-1"></div>
                                    <div class="col-lg-8">
                                        <h5 class="card-title m-0">
                                            CONTATORE SMS INVIATI ANNO {{ form.year }}
                                        </h5>
                                        <div class="row mt-4">
                                            <div class="col-lg-3">
                                                SMS inviati<br />
                                                {{form.counter.colonretto.sms_sent}}
                                            </div>
                                            <div class="col-lg-3">
                                                SMS conferma<br />
                                                {{form.counter.colonretto.sms_confirm}}
                                            </div>
                                            <div class="col-lg-3">
                                                SMS sollecito<br />
                                                {{form.counter.colonretto.sms_reminder}}
                                            </div>  
                                            <div class="col-lg-3 text-center font-weight-bold">
                                                TOTALE <br />
                                                {{form.counter.colonretto.total_sms}}
                                            </div>                                                                                                                                    
                                        </div>                                                                               
                                    </div>
                                </div> 
                            </div> 
                        </div> 
                    </div>             
                    <div class="card-space mb-3">    
                        <div class="card card-bg no-after">
                            <div class="card-body">                                  
                                <div class="row">
                                    <div class="col-lg-3">

                                        <h5 class="card-title m-0">
                                            SMS <br />SCREENING HCV
                                        </h5>
                                        <div class="card-text pb-3">
                                        Il numero massimo di sms che possono essere inviati
                                        </div>

                                        <strong>{{form.max_sms_hcv}}</strong>
                                    </div>
                                    <div class="col-lg-1"></div>
                                    <div class="col-lg-8">
                                        <h5 class="card-title m-0">
                                            CONTATORE SMS INVIATI ANNO {{ form.year }}
                                        </h5>
                                        <div class="row mt-4">
                                            <div class="col-lg-3">
                                                SMS inviati<br />
                                                {{form.counter.hcv.sms_sent}}
                                            </div>
                                            <div class="col-lg-3">
                                                SMS conferma<br />
                                                {{form.counter.hcv.sms_confirm}}
                                            </div>
                                            <div class="col-lg-3">
                                                SMS sollecito<br />
                                                {{form.counter.hcv.sms_reminder}}
                                            </div>  
                                            <div class="col-lg-3 text-center font-weight-bold">
                                                TOTALE <br />
                                                {{form.counter.hcv.total_sms}}
                                            </div>                                                                                                                                    
                                        </div>                                                                               
                                    </div>
                                </div>                                         
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import {
        required,
        minLength,
        maxLength,
        numeric
    } from "vuelidate/lib/validators"

    export default {
        data() {
            return {
                form: {
                    year: 2023,
                    sms: [],
                    max_sms_mammografico: null,
                    max_sms_citologico: null,
                    max_sms_colonretto: null,
                    max_sms_hcv: null,
                    counter: {
                        mammografico: {
                            sms_sent: null,
                            sms_confirm: null,
                            sms_reminder: null,
                            total_sms: null
                        },
                        citologico: {
                            sms_sent: null,
                            sms_confirm: null,
                            sms_reminder: null,
                            total_sms: null
                        },
                        colonretto: {
                            sms_sent: null,
                            sms_confirm: null,
                            sms_reminder: null,
                            total_sms: null
                        },  
                        hcv: {
                            sms_sent: null,
                            sms_confirm: null,
                            sms_reminder: null,
                            total_sms: null
                        }                                           
                    }
                },
                loaders: {
                    reading: {
                        settings: true
                    },
                    update: {
                        max_sms: false
                    }
                },
                validations: {
                    update: {
                        max_sms: {
                            KO: false,
                            OK: false
                        }
                    }
                }
            }
        },
        validations() {
            return {
                form: {
                    sms: {
                        max_sms: {
                            required,
                            minLength: minLength(
                                3
                            ),
                            maxLength: maxLength(
                                7
                            ),
                            numeric
                        }
                    }
                }
            }
        },
        computed : {
            years() {
                const year = new Date().getFullYear()
                return Array.from({length: year - 2022}, (value, index) => 2023 + index)
            }
        },          
        methods: {
            change_year(year) {
                if(!year) {
                    this.form.year = 2023
                } else {
                    this.form.year = year
                    this.read_settings();
                }
                
            },   

            async read_settings() {
                try {

                    this.loaders.reading.settings = true

                    this.form.sms = (
                        await this.axios.get(`admin/settings/read/settings`, {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        })
                    ).data.setting_db

                    const year = this.form.year

                    for(let max_sms of this.form.sms) {
                        if(max_sms.id_screening == 2){
                            this.form.max_sms_mammografico = max_sms.max_sms

                            if(year == 2024){
                                this.form.max_sms_mammografico = 76928
                            }    
                        } else if(max_sms.id_screening == 1){
                            this.form.max_sms_citologico = max_sms.max_sms
                            if(year == 2024){
                                this.form.max_sms_citologico = 65016
                            }                             
                        } else if(max_sms.id_screening == 3){
                            this.form.max_sms_colonretto = max_sms.max_sms
                            if(year == 2024){
                                this.form.max_sms_colonretto = 38780
                            }                               
                        } else {
                            this.form.max_sms_hcv = max_sms.max_sms
                            if(year == 2024){
                                this.form.max_sms_hcv = 1047
                            }                             
                        }
                    }  
                    
                    

                    const sent_sms_mammografico = await this.axios.post(`user/appointments/read/counter_sms_sent_mammografico`, {year: year}, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    const confirm_sms_mammografico= await this.axios.post(`user/appointments/read/counter_sms_confirm_mammografico`, {year: year}, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })
                    
                    const reminder_sms_mammografico = await this.axios.post(`user/appointments/read/counter_sms_reminder_mammografico`, {year: year}, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })                    
                    

                    let counter_sent_sms_mammografico;
                    let counter_confirm_sms_mammografico;
                    let counter_reminder_sms_mammografico;

                    if(sent_sms_mammografico.data.appointments_db.length == 0){
                        counter_sent_sms_mammografico = 0;
                    } else {
                        for(let sentSmsMammografico of sent_sms_mammografico.data.appointments_db) {
                            counter_sent_sms_mammografico = sentSmsMammografico.counter;
                        }
                    } 

                    if(confirm_sms_mammografico.data.appointments_db.length == 0){
                        counter_confirm_sms_mammografico = 0;
                    } else {
                        for(let confirmSmsMammografico of confirm_sms_mammografico.data.appointments_db) {
                            counter_confirm_sms_mammografico = confirmSmsMammografico.counter;
                        }    
                    }   

                    if(reminder_sms_mammografico.data.appointments_db.length == 0){
                        counter_reminder_sms_mammografico = 0;
                    }  else {

                        for(let reminderSmsMammografico of reminder_sms_mammografico.data.appointments_db) {                       
                            counter_reminder_sms_mammografico = reminderSmsMammografico.counter;
                        } 
                    }                                   
                    
                    const totSmsSentMammografico = counter_sent_sms_mammografico + counter_confirm_sms_mammografico + counter_reminder_sms_mammografico;

                    this.form.counter.mammografico.sms_sent = counter_sent_sms_mammografico;
                    this.form.counter.mammografico.sms_confirm = counter_confirm_sms_mammografico;
                    this.form.counter.mammografico.sms_reminder = counter_reminder_sms_mammografico;
                    this.form.counter.mammografico.total_sms = totSmsSentMammografico;
                    //-----------

                    const sent_sms_citologico = await this.axios.post(`user/appointments/read/counter_sms_sent_citologico`, {year: year}, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    const confirm_sms_citologico = await this.axios.post(`user/appointments/read/counter_sms_confirm_citologico`, {year: year}, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })
                    
                    const reminder_sms_citologico = await this.axios.post(`user/appointments/read/counter_sms_reminder_citologico`, {year: year}, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })                    
                    

                    let counter_sent_sms_citologico;
                    let counter_confirm_sms_citologico;
                    let counter_reminder_sms_citologico;

                    if(sent_sms_citologico.data.appointments_db.length == 0){
                        counter_sent_sms_citologico = 0;
                    } else {
                        for(let sentSmsCitologico of sent_sms_citologico.data.appointments_db) {
                            counter_sent_sms_citologico = sentSmsCitologico.counter;
                        }
                    } 

                    if(confirm_sms_citologico.data.appointments_db.length == 0){
                        counter_confirm_sms_citologico = 0;
                    } else {
                        for(let confirmSmsCitologico of confirm_sms_citologico.data.appointments_db) {
                            counter_confirm_sms_citologico = confirmSmsCitologico.counter;
                        }    
                    }   

                    if(reminder_sms_citologico.data.appointments_db.length == 0){
                        counter_reminder_sms_citologico = 0;
                    }  else {

                        for(let reminderSmsCitologico of reminder_sms_citologico.data.appointments_db) {                       
                            counter_reminder_sms_citologico = reminderSmsCitologico.counter;
                        } 
                    }                                   
                    
                    const totSmsSentCitologico = counter_sent_sms_citologico + counter_confirm_sms_citologico + counter_reminder_sms_citologico;

                    this.form.counter.citologico.sms_sent = counter_sent_sms_citologico;
                    this.form.counter.citologico.sms_confirm = counter_confirm_sms_citologico;
                    this.form.counter.citologico.sms_reminder = counter_reminder_sms_citologico;
                    this.form.counter.citologico.total_sms = totSmsSentCitologico;

                    //-----------

                    const sent_sms_colonretto = await this.axios.post(`user/appointments/read/counter_sms_sent_colonretto`, {year: year}, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    const confirm_sms_colonretto = await this.axios.post(`user/appointments/read/counter_sms_confirm_colonretto`, {year: year}, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })
                    
                    const reminder_sms_colonretto = await this.axios.post(`user/appointments/read/counter_sms_reminder_colonretto`, {year: year}, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })                    
                    

                    let counter_sent_sms_colonretto;
                    let counter_confirm_sms_colonretto;
                    let counter_reminder_sms_colonretto;

                    if(sent_sms_colonretto.data.appointments_db.length == 0){
                        counter_sent_sms_colonretto = 0;
                    } else {
                        for(let sentSmsColonretto of sent_sms_colonretto.data.appointments_db) {
                            counter_sent_sms_colonretto = sentSmsColonretto.counter;
                        }
                    } 

                    if(confirm_sms_colonretto.data.appointments_db.length == 0){
                        counter_confirm_sms_colonretto = 0;
                    } else {
                        for(let confirmSmsColonretto of confirm_sms_colonretto.data.appointments_db) {
                            counter_confirm_sms_colonretto = confirmSmsColonretto.counter;
                        }    
                    }   

                    if(reminder_sms_colonretto.data.appointments_db.length == 0){
                        counter_reminder_sms_colonretto = 0;
                    }  else {

                        for(let reminderSmsColonretto of reminder_sms_colonretto.data.appointments_db) {                       
                            counter_reminder_sms_colonretto = reminderSmsColonretto.counter;
                        } 
                    }                                   
                    
                    const totSmsSentColonretto = counter_sent_sms_colonretto + counter_confirm_sms_colonretto + counter_reminder_sms_colonretto;

                    this.form.counter.colonretto.sms_sent = counter_sent_sms_colonretto;
                    this.form.counter.colonretto.sms_confirm = counter_confirm_sms_colonretto;
                    this.form.counter.colonretto.sms_reminder = counter_reminder_sms_colonretto;
                    this.form.counter.colonretto.total_sms = totSmsSentColonretto;

                    //-----------

                    const sent_sms_hcv = await this.axios.post(`user/appointments/read/counter_sms_sent_hcv`, {year: year}, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })

                    const confirm_sms_hcv = await this.axios.post(`user/appointments/read/counter_sms_confirm_hcv`, {year: year}, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })
                    
                    const reminder_sms_hcv = await this.axios.post(`user/appointments/read/counter_sms_reminder_hcv`, {year: year}, {
                        headers:{
                            Authorization: `bearer ${this.$store.state.auth.token}`
                        }
                    })                    
                    

                    let counter_sent_sms_hcv;
                    let counter_confirm_sms_hcv;
                    let counter_reminder_sms_hcv;

                    if(sent_sms_hcv.data.appointments_db.length == 0){
                        counter_sent_sms_hcv = 0;
                    } else {
                        for(let sentSmsHcv of sent_sms_hcv.data.appointments_db) {
                            counter_sent_sms_hcv = sentSmsHcv.counter;
                        }
                    } 

                    if(confirm_sms_hcv.data.appointments_db.length == 0){
                        counter_confirm_sms_hcv = 0;
                    } else {
                        for(let confirmSmsHcv of confirm_sms_hcv.data.appointments_db) {
                            counter_confirm_sms_hcv = confirmSmsHcv.counter;
                        }    
                    }   

                    if(reminder_sms_hcv.data.appointments_db.length == 0){
                        counter_reminder_sms_hcv = 0;
                    }  else {

                        for(let reminderSmsHcv of reminder_sms_hcv.data.appointments_db) {                       
                            counter_reminder_sms_hcv = reminderSmsHcv.counter;
                        } 
                    }                                   
                    
                    const totSmsSentHcv = counter_sent_sms_hcv + counter_confirm_sms_hcv + counter_reminder_sms_hcv;

                    this.form.counter.hcv.sms_sent = counter_sent_sms_hcv;
                    this.form.counter.hcv.sms_confirm = counter_confirm_sms_hcv;
                    this.form.counter.hcv.sms_reminder = counter_reminder_sms_hcv;
                    this.form.counter.hcv.total_sms = totSmsSentHcv;

                }
                catch(e) {
                    if(e.response.status == 401 && e.response.statusText == `Unauthorized`) {

                        this.$toast.open({
                            message: `La tua sessione è scaduta, effettua nuovamente il login`,
                            type:    `error`
                        })

                        this.$router.push('/sessionescaduta')

                        this.loaders.reading.settings = false
                    }
                }
                finally {
                    this.loaders.reading.settings = false
                }
            },
            async update_settings() {

                this.loaders.update.max_sms        = true
                this.validations.update.max_sms.KO = false

                try {
                    await this.axios.post(
                        "admin/settings/update/settings",
                        {
                            max_sms: this.form.sms.max_sms,
                            id: this.form.sms.id
                        },
                        {
                            headers:{
                                Authorization: `bearer ${this.$store.state.auth.token}`
                            }
                        }
                    )

                    const message = "Limite sms modificato con successo"

                    this.validations.update.max_sms.OK = message

                    this.$toast.open({
                        message,
                        type: "success"
                    })
                    
                }
                catch(e) {

                    console.error(
                        "ERROREEE",
                        e
                    )

                    const {
                        message
                    } = e.response.data

                    this.validations.update.max_sms.KO = message

                    this.$toast.open({
                        message,
                        type: "error"
                    })

                }
                finally {
                    this.loaders.update.max_sms = false
                }
                    
            }
        },
        mounted() {
            this.read_settings()
        }     
    }
</script>